/* @jsx jsx */
import {
  jsx,
  Box,
  Button,
  Heading,
  Label,
  Link,
  Input,
  Text,
  Theme,
} from "theme-ui";
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Flex from "./Flex";

const hubspotFormQuery = graphql`
  {
    contentfulHero {
      hubspotForm {
        title
        decorativeText
        portalId
        formId
        hopinId
      }
    }
  }
`;

const submitMessageStyles = {
  color: `muted`,
  height: `100%`,
  textAlign: "center",
  p: `4`,
};
const formStyles = {
  position: `relative`,
  display: `grid`,
  marginLeft: [`-32px`, `-64px`, `-64px`, 0],
  marginRight: [`-32px`, `-64px`, `-64px`, 0],
  p: [`4`, `5`],
  gridTemplateAreas: [
    ` 
      "title"
      "fname"
      "lname"
      "email"
      "company"
      "button"
    `, // mobile view
    `
      "title title"
      "fname lname"
      "email company"
      "button button"
    `, // tablet view
    `
      "title title"
      "fname lname"
      "email company"
      "button button"
    `, // small desktop view
    `
      "title"
      "fname"
      "lname"
      "email"
      "company"
      "button"
    `, // desktop view
  ],
  gridColumnGap: `2`,
  gridRowGap: `3`,
  gridTemplateColumns: [`1fr`, `1fr 1fr`, `1fr 1fr`, `1fr`],
  "&::before": {
    // use a pseudo element means the opacity won't dim the form text too
    content: `""`,
    position: `absolute`,
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: `5`,
    opacity: 0.2,
    background: (theme: Theme) =>
      `linear-gradient(to bottom, ${theme.colors.primary}, ${theme.colors.secondary})`,
  },
};

const formTextStyles = {
  gridArea: `title`,
  textAlign: [`left`, `center`, `center`, `left`],
};
const decorativeTextStyles = {
  variant: `text.capitalized`,
  color: `vivid`,
};
const formTitleStyles = {
  fontSize: `5`,
  mt: `2`,
  mb: `4`,
  mx: `0 auto`,
};

const activeLabelStyles = {
  "&:focus-within > label": { color: `secondary` },
};
const firstNameStyles = {
  gridArea: `fname`,
  position: `relative`,
  ...activeLabelStyles,
};
const lastNameStyles = {
  gridArea: `lname`,
  position: `relative`,
  ...activeLabelStyles,
};
const emailStyles = {
  gridArea: `email`,
  position: `relative`,
  ...activeLabelStyles,
};
const companyStyles = {
  gridArea: `company`,
  position: `relative`,
  ...activeLabelStyles,
};

const buttonStyles = {
  gridArea: `button`,
  display: `flex`,
  alignItems: `center`,
  width: `fit-content`,
  mt: `3`,
  justifySelf: [`flex-start`, `center`, `center`, `flex-start`],
};

const sendIconStyes = {
  height: 24,
  ml: `2`,
};

const possiblePlaceholders = [
  {
    firstName: `Ada`,
    lastName: `Lovelace`,
    email: `ada@example.com`,
    company: `Analytical Engine`,
  },
  {
    firstName: `Alan`,
    lastName: `Turing`,
    email: `alan@example.com`,
    company: `Enigma`,
  },
  {
    firstName: `Anders`,
    lastName: `Hejlsberg`,
    email: `anders@example.com`,
    company: `Microsoft`,
  },
  {
    firstName: `Barbara`,
    lastName: `Liskov`,
    email: `barbara@example.com`,
    company: `MIT`,
  },
  {
    firstName: `Frances`,
    lastName: `Allen`,
    email: `frances@example.com`,
    company: `IBM`,
  },
  {
    firstName: `Grace`,
    lastName: `Hopper`,
    email: `grace@example.com`,
    company: `US Navy`,
  },
  {
    firstName: `Jean`,
    lastName: `Bartik`,
    email: `jean@example.com`,
    company: `ENIAC`,
  },
  {
    firstName: `Jean`,
    lastName: `Sammet`,
    email: `jean@example.com`,
    company: `FORMAC`,
  },
  {
    firstName: `Linus`,
    lastName: `Torvalds`,
    email: `linus@example.com`,
    company: `Linux`,
  },
  {
    firstName: `Margaret`,
    lastName: `Hamilton`,
    email: `margaret@example.com`,
    company: `MIT`,
  },
  {
    firstName: `Steve`,
    lastName: `Wozniak`,
    email: `woz@example.com`,
    company: `Apple Inc.`,
  },
  {
    firstName: `Yukihiro`,
    lastName: `Matsumoto`,
    email: `matz@example.com`,
    company: `Ruby`,
  },
];
const getRandomElementFromArray = (
  items: { firstName: string; lastName: string; email: string }[]
) => {
  return items[Math.floor(Math.random() * items.length)];
};

const HubspotForm: React.FC<{
  title?: string;
  decorativeText?: string;
}> = ({ title, decorativeText }) => {
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(``);
  const {
    contentfulHero: {
      hubspotForm: {
        title: contentfulTitle,
        decorativeText: contentfulDecorativeText,
        portalId,
        formId,
        hopinId,
      },
    },
  } = useStaticQuery(hubspotFormQuery);

  const placeholder = getRandomElementFromArray(possiblePlaceholders);
  function onSubmit(event) {
    event.preventDefault();

    const elementsArray = [...event.target.elements];
    const formData = elementsArray.reduce((acc, elem) => {
      if (elem.id) acc.push({ name: elem.id, value: elem.value });
      return acc;
    }, []);

    const body = JSON.stringify({ fields: formData });
    fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
      {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`,
        },
        body,
      }
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.status === `error`) {
          console.error(json.message);
          setErrorMessage(
            `Uh oh :( something went wrong submitting. We use HubSpot and sometimes they don't play friendly with validating data. Reach out to us if you keep having issues!`
          );
        }
        setHasSubmitted(true);
      });
  }

  return (
    <Box>
      {hasSubmitted ? (
        <Flex
          gap={2}
          sx={submitMessageStyles}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {errorMessage ? (
            <Text color="secondary">{errorMessage}</Text>
          ) : (
            <React.Fragment>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                sx={{ height: 48 }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <Text color="muted" sx={{ fontSize: 3 }}>
                Thanks for registering for GatsbyConf 2021! Keep an eye out for
                event details in your inbox and be sure to invite your friends
                to <Link href="https://ctt.ac/a2H89">join you on Twitter.</Link>
              </Text>
            </React.Fragment>
          )}
        </Flex>
      ) : (
        <form onSubmit={onSubmit} sx={formStyles}>
          <Box sx={formTextStyles}>
            <Text sx={decorativeTextStyles}>
              {decorativeText || contentfulDecorativeText}
            </Text>
            <Heading as="h2" sx={formTitleStyles}>
              {title || contentfulTitle}
            </Heading>
          </Box>
          <Box sx={firstNameStyles}>
            <Label htmlFor="firstname">First Name</Label>
            <Input
              type="text"
              id="firstname"
              placeholder={placeholder.firstName}
              required
            />
            <InputDecorator />
          </Box>
          <Box sx={lastNameStyles}>
            <Label htmlFor="lastname">Last Name</Label>
            <Input
              type="text"
              id="lastname"
              placeholder={placeholder.lastName}
              required
            />
            <InputDecorator />
          </Box>
          <Box sx={emailStyles}>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              id="email"
              placeholder={placeholder.email}
              required
            />
            <InputDecorator />
          </Box>
          <Box sx={companyStyles}>
            <Label htmlFor="company">Company</Label>
            <Input
              type="text"
              id="company"
              placeholder={placeholder.company}
              required
            />
            <InputDecorator />
          </Box>
          <Input
            type="hidden"
            id="hopin_ticket_integration_code"
            value={hopinId}
            required
          />
          <Button sx={buttonStyles}>
            Claim My Spot{" "}
            <svg fill="currentColor" viewBox="0 0 48 48" sx={sendIconStyes}>
              <path d="M4.02 42L46 24 4.02 6 4 20l30 4-30 4z" />
            </svg>
          </Button>
        </form>
      )}
    </Box>
  );
};

export default HubspotForm;

const InputDecorator = () => {
  return (
    <Box
      sx={{
        position: `absolute`,
        top: 0,
        right: 0,
        bottom: 0,
        display: `flex`,
        alignItems: `center`,
        transform: `translateY(14px) translateX(-10px)`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        sx={{ height: 24, opacity: 0 }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </Box>
  );
};
