/* @jsx jsx */
import React from "react";
import { jsx, SxProps } from "theme-ui";
import { FlexboxProps } from "styled-system";

interface FlexProps extends FlexboxProps, SxProps {
  gap?: number | number[];
  as?: Parameters<typeof React.createElement>[0];
  children: React.ReactNode;
}

function Flex(props: FlexProps) {
  let {
    children,
    alignItems,
    alignContent,
    justifyItems,
    justifyContent,
    flexWrap,
    flexDirection,
    flex,
    flexGrow,
    flexShrink,
    flexBasis,
    justifySelf,
    alignSelf,
    order,
    gap,
    ...rest
  } = props;

  return jsx(
    props.as || `div`,
    {
      ...rest,
      // @ts-ignore
      sx: {
        display: `flex`,
        alignItems,
        alignContent,
        justifyItems,
        justifyContent,
        flexWrap,
        flexDirection,
        flex,
        flexGrow,
        flexShrink,
        flexBasis,
        justifySelf,
        alignSelf,
        order,
        "> *": {
          [flexDirection === "column" ? `marginBottom` : `marginRight`]: gap,
        },
        "> *:last-child": {
          [flexDirection === "column" ? `marginBottom` : `marginRight`]: 0,
        },
        ...(props.sx || {}),
      },
    },
    children
  );
}

export default Flex;
