/* @jsx jsx */
import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { Box, jsx } from "theme-ui";
import Footer from "./Footer";
import "../styles/fonts.css";
import "../styles/images.css";

const seoQuery = graphql`
  {
    contentfulConferenceDetails {
      socialMediaImage {
        file {
          url
        }
      }
    }
  }
`;

const Layout: React.FC<{}> = ({ children }) => {
  const data = useStaticQuery(seoQuery);
  let ogImage = ``;
  let url = `https://gatsbyconf.com/`;
  let metaTitle = `GatsbyConf`;
  let metaDescription = `The Future Web is Here`;

  if (data.contentfulConferenceDetails) {
    ogImage = `http:${data.contentfulConferenceDetails.socialMediaImage?.file?.url}`;
  }

  return (
    <Box as="main" variant="layout.main">
      <Helmet
        meta={[
          {
            name: `image`,
            content: ogImage,
          },
          {
            property: `og:url`,
            content: url,
          },

          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: ogImage,
          },
          /* Twitter Card tags */
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: `@GatsbyJS`,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]}
      />
      {children}
      <Footer />
    </Box>
  );
};

export default Layout;
