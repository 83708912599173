/* @jsx jsx */
import * as React from "react";
import { jsx } from "theme-ui";
import ConfLogoSrc from "../images/GatsbyConfLogo.svg";

const ConfLogo: React.FC<{}> = (props) => {
  return (
    <img
      alt="GatsbyConf logo"
      width={131}
      height={72}
      src={ConfLogoSrc}
      {...props}
    />
  );
};

export default ConfLogo;
