import {
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaDiscord,
  FaLinkedin,
} from "react-icons/fa";

export const socialLinks = [
  {
    name: `Twitter`,
    url: `https://twitter.com/gatsbyjs`,
    icon: FaTwitter,
  },
  {
    name: `LinkedIn`,
    url: `https://www.linkedin.com/company/gatsbyjs`,
    icon: FaLinkedin,
  },

  {
    name: `Instagram`,
    url: `https://www.instagram.com/gatsbyjs/`,
    icon: FaInstagram,
  },
  {
    name: `Youtube`,
    url: `https://www.youtube.com/gatsbyjs`,
    icon: FaYoutube,
  },
  {
    name: `Github`,
    url: `https://github.com/gatsbyjs`,
    icon: FaGithub,
  },
  {
    name: `Discord`,
    url: `https://gatsby.dev/discord`,
    icon: FaDiscord,
  },
];
