/* @jsx jsx */
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { jsx, Text } from "theme-ui";
import Flex from "./Flex";
import { socialLinks } from "../utils/socialLinks";
import GatsbyLogo from "../images/GatsbyLogo.svg";

const footerQuery = graphql`
  {
    contentfulFooter {
      footerLinks {
        href
        text
      }
    }
  }
`;

const footerStyles = {
  alignItems: [`center`, null, null, `inherit`],
  flexDirection: `column`,
  paddingBottom: `4`,
  paddingTop: `5`,
};

const listContainerStyles = {
  width: `100%`,
  flexDirection: ["column", null, null, "row"],
  justifyContent: ["center", null, null, "space-between"],
  alignItems: `center`,
  mb: 3,
  px: [4, 5, 6, 0],
};

const listStyles = {
  listStyle: `none`,
  m: 0,
  px: 0,
  display: `flex`,
  flexDirection: [`column`, `row`],
  alignItems: ["center", "flex-start"],
};

const legalListStyles = {
  "* + *": {
    ml: [0, `4`],
  },
};

const socialListStyles = {
  paddingTop: [`32px`, `32px`, `32px`, 0],
  flexDirection: `row`,
  "* + *": {
    ml: [`3`, `4`],
  },
};

const externalLinkStyles = { fontSize: `3`, variant: "text.link" };

const copyrightStyles = {
  fontSize: `2`,
  color: `muted`,
  textAlign: `center`,
  px: [4, 5, 6, 0],
};

const ExternalLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => (
  <a sx={externalLinkStyles} href={href} target="_blank" rel="noreferrer">
    {children}
  </a>
);

const Footer: React.FC<{}> = () => {
  const {
    contentfulFooter: { footerLinks },
  } = useStaticQuery(footerQuery);

  return (
    <Flex as="footer" sx={footerStyles}>
      <Flex sx={listContainerStyles}>
        <img
          alt=""
          width={90}
          height={24}
          src={GatsbyLogo}
          sx={{ mb: [4, null, null, 0] }}
        />
        <ul sx={{ ...listStyles, ...legalListStyles }}>
          {footerLinks.map((link) => (
            <li key={link.href}>
              <ExternalLink href={link.href}>{link.text}</ExternalLink>
            </li>
          ))}
        </ul>
        <ul
          sx={{
            ...listStyles,
            ...socialListStyles,
          }}
        >
          {socialLinks.map((item) => {
            const { name, icon: Icon, url } = item;

            return (
              <li>
                <ExternalLink href={url}>
                  <Icon title={`Gatsby on ${name}`} />
                </ExternalLink>
              </li>
            );
          })}
        </ul>
      </Flex>
      <Text sx={copyrightStyles}>
        GatsbyConf is a Gatsby, Inc. event.{" "}
        <br sx={{ display: [`block`, `none`] }} /> © Gatsby, Inc. All rights
        reserved.
      </Text>
    </Flex>
  );
};

export default Footer;
